// Required polyfill for `@kimoby/jsonapi-client` to work with older browsers.
import 'url-search-params-polyfill';

// TODO: import '@/app/bullet-request-interceptor' a.k.a find a way to share code between the application

// TODO: setup OpenReplay.

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { i18n } from '@/portal/i18n'

import App from '@/portal/App.vue'
import router from '@/portal/router'
import Notifications from '@kyvg/vue3-notification'

// Stylesheets

import 'virtual:uno.css'
import '@unocss/reset/tailwind.css'
import '@kimoby/ui-kit-components-vue/style.css'

// TODO: Use the same CSS reset as the app. "@/app/assets/scss/reset.css"

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(Notifications)
app.mount('#app')
