import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  // TODO: configure the base URL for the router.
  history: createWebHistory(),
  routes: [
    {
      name: 'Profile',
      path: '/profiles/:profileId',
      props: true,
      component: () => import('@/portal/views/Profile.vue'),
      children: [
        {
          path: "",
          name: "ProfileHome",
          props: true,
          component: () => import("@/portal/views/ProfileHome.vue"),
        },
        {
          name: "LoanerBooking",
          path: "loaner_bookings/:loanerBookingId",
          children: [
            {
              name: "RentalAgreement",
              path: "rental_agreement",
              props: true,
              component: () => import("@/portal/views/LoanerBookingRentalAgreement.vue"),
            },
            {
              name: "RentalReceipt",
              path: "rental_receipt",
              props: true,
              component: () => import("@/portal/views/LoanerBookingRentalReceipt.vue"),
            }
          ]
        }
      ],
    },
    {
      name: 'NotFound',
      path: '/not-found',
      component: () => import('@/portal/views/NotFound.vue'),
    },
    {
      // Catch-all route to redirect to the not found page.
      path: '/:pathMatch(.*)*',
      redirect: { name: 'NotFound' }
    },
  ],
});

export default router;
